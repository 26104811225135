import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Switch, useLocation } from "react-router-dom";
import Home from "./components/home/home";
import UserRoute from "./components/PrivateRoutes/userRoute";
import Unboxing from "./components/unboxing/unboxing";
import Product from "./components/product/product";
import ProfilePage from "./components/profile/index";
import VerifyEmail from "./components/verifyEmail/verifyEmail";
import PageNotFound from "./components/404_page/pagenotfound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Boxdetails from "./components/unboxing/Boxdetails";
import Battles from "./components/battles/battles";
import WatchBattle from "./components/battles/watchBattle";
import WithdrawDeposit from "./components/withDrawDeposit/withdrawDeposit";
import ProductDetails from "./components/productdetails/productdetails";
import { useDispatch } from "react-redux";
//import MessengerCustomerChat from "react-messenger-customer-chat";
//import { MessengerChat } from "react-messenger-chat-plugin";
import "./App.css";

import {
  getLoginUser,
  getAllBoxes,
  //getGameResult,
  getAllCategorySelectList,
  checkTokenExpiry,
  getCartProducts,
  getDistinctInventoryProducts,
} from "./reducers/userReducer";
// import WatchBattle from "./components/battles/watchBattle";
// import WithdrawDeposit from "./components/withDrawDeposit/withdrawDeposit";
// import BattleList from './components/battles/battleList';
import BattleHistory from "./components/battles/battleHistory";
import { Cart } from "./components/cart/Cart";
import { CheckOut } from "./components/checkout/CheckOut";
import CreateBattles from "./components/battles/createBattles";
import Inventory from "./components/profile/Inventory";
import ThanksOrderPage from "./components/PopUp/thanks-page";
import FaqPage from "./components/faq/faqpage";
import "./assets/css/line-awesome.min.css";
import "animate.css";
import "./assets/css/main.css";
import SuccessPage from "./components/paymentPages/successPage";
import FailurePage from "./components/paymentPages/failurePage";
import ResetPassword from "./components/ForgetPassword/resetPassword";
import ContactUs from "./components/contactus/contactus";
import HowToPlay from "./components/howToPlay/howtoplay";
import PrivacyPolicy from "./components/privacy_policy";
import Termsconditions from "./components/terms_conditions";
import "./responsive.css";
import { useTranslation } from "react-i18next";

// import Reset from "./components/forget/reset";

const App = () => {
  const location = useLocation();
  const token = localStorage.getItem("inboxi#@user");
  const { i18n } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    i18n?.changeLanguage("en");
    if (localStorage.getItem("inboxi#@user")) {
      dispatch(getLoginUser());
      dispatch(getCartProducts());
      dispatch(getDistinctInventoryProducts());
    }
    dispatch(getAllBoxes());
    dispatch(getAllCategorySelectList());
    dispatch(checkTokenExpiry());
    // dispatch(getCartProducts())
    // dispatch(getDistinctInventoryProducts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token]);

  useEffect(() => {
    // if(location.pathname.search("/boxdetails") === -1){
    //   console.log("path", location.pathname.search("/boxdetails"))
    // window.scrollTo({ top: 0, behavior: "smooth" });
    // }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  return (
    <div className="App">
      <React.Fragment>
        <Switch>
          <Route exact path="/" component={() => <Home />} />
          <Route exact path="/privacy" component={() => <PrivacyPolicy />} />
          <Route
            exact
            path="/agreement"
            component={() => <Termsconditions />}
          />
          <Route exact path="/verify-email" component={() => <VerifyEmail />} />
          <Route exact path="/boxes" component={() => <Unboxing />} />
          <UserRoute exact path="/profile" component={() => <ProfilePage />} />
          <Route exact path="/boxdetails" render={() => <Boxdetails />} />
          <Route exact path="/battles" component={() => <Battles />} />
          <Route exact path="/watch-battle" component={() => <WatchBattle />} />
          <Route
            exact
            path="/withdrawDeposit"
            component={() => <WithdrawDeposit />}
          />
          <Route
            exact
            path="/create-battle"
            component={() => <CreateBattles />}
          />

          <Route exact path="/cart" component={() => <Cart />} />
          <Route exact path="/checkout" component={() => <CheckOut />} />
          <UserRoute exact path="/store" component={() => <Product />} />

          <Route
            exact
            path="/product-details"
            component={() => <ProductDetails />}
          />
          <Route
            exact
            path="/battleHistory"
            component={() => <BattleHistory />}
          />
          <Route exact path="/inventory" component={() => <Inventory />} />
          <Route exact path="/thanks" component={() => <ThanksOrderPage />} />
          <Route exact path="/faq" component={() => <FaqPage />} />
          <Route exact path="/contact-us" component={() => <ContactUs />} />
          <Route exact path="/how-to-play" component={() => <HowToPlay />} />
          <Route
            exact
            path="/paymentSuccess"
            component={() => <SuccessPage />}
          />
          <Route
            exact
            path="/paymentFailed"
            component={() => <FailurePage />}
          />

          <Route exact path="/reset-pwd" component={() => <ResetPassword />} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default App;

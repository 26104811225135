import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import logo from "../../assets/images/boxilogof.png";
import logo from "../../assets/images/cily-logo.png";

import "./footer.css";
import shape2 from "../../assets/images/elements/round-shape-2-new.png";
// import storebtn1 from '../../assets/images/icon/store-btn/1.png';
// import storebtn2 from '../../assets/images/icon/store-btn/2.png'

// import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const handleFBCLick = () => {
    try {
      // console.log('from try');

      window.location = "fb://page/110842648285271";
    } catch (error) {
      // console.log('from catch', error);
      window.open("https://www.facebook.com/110842648285271", "_blank");
    }
  };
  return (
    <footer className='footer-section'>
      <div className='bg-shape--top'>
        <img src={shape2} alt='bg' />
      </div>
      <div className='container pt-55'>
        <div className='row pb-3 align-items-center'>
          <div className='col-lg-4'>
            <ul className='app-btn'>
              {/* <li><a href="#0"><img src={storebtn1} alt="bg" /></a></li>
              <li><a href="#0"><img src={storebtn2} alt="bg" /></a></li> */}
              <li>
                {" "}
                <Link className='site-logo site-title' to='/'>
                  <img src={logo} alt='site-logo' />
                </Link>
              </li>
            </ul>
          </div>
          <div className='col-lg-8'>
            <ul className='short-links justify-content-lg-end justify-content-center'>
              <li>
                <Link to='/faq'>FAQs</Link>
              </li>
              <li>
                <Link to='/contact-us'>{t("footer.contact")}</Link>
              </li>
              <li>
                <Link to='/agreement'>{t("footer.termsOfServices")}</Link>
              </li>
              <li>
                <Link to='/privacy'>{t("footer.privacy")}</Link>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className='row py-3 align-items-center'>
          <div className='col-lg-12'>
            <p className='copy-right-text text-lg-left text-center mb-3'>
              {t("footer.copyrightText1")} © 2024 <Link to='/'>Cilycase</Link>. All Rights Reserved.
            </p>
          </div>
          <div className='col-lg-12'>
            <ul className='social-links justify-content-center'>
              <li className='desk-view-url'>
                <a
                  href='/'
                  target='_blank'
                  rel='noreferrer'>
                  <i className='fab fa-facebook-f'></i>
                </a>
              </li>
              <li className='mob-view-url' onClick={handleFBCLick}>
                <span>
                  <i className='fab fa-facebook-f'></i>
                </span>
              </li>
              <li>
                <a
                  href='/'
                  target='_blank'
                  rel='noreferrer'>
                  <i class='fa fa-instagram'></i>
                </a>
              </li>
              <li>
                <a
                  href='/'
                  target='_blank'
                  rel='noreferrer'>
                  <i class='fa fa-youtube-play'></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
